@import '~apriori-react-core/src/styles/global';


/* Button.
 ------------------------------------------------- */
.btn-primary:focus {
  box-shadow: none !important;
}


/* Card.
 ------------------------------------------------- */
.card {
  margin-bottom: 10px;
  transition: border 0.5s;

  &:hover {
    border: 1px solid $cyan;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .card-body {
    padding: 1rem !important;
    position: relative;

    .card-title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: .25rem !important;

      .title-left {
        align-items: center;
        display: flex;
      }

      .title-right {
        align-items: center;
        display: flex;
      }
    }
  }

  .card-footer {
    align-items: center !important;
    background-color: transparent !important;
    display: flex !important;
  }

  .card-text {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}


/* Dropdown menus.
   TODO (aj): Remove in favor of a common style.
 ------------------------------------------------- */
.dropdown {
  height: 38px !important;

  .dropdown-item {
    font-size: 0.9rem !important;
    font-weight: 300;

    &.no-hover {
      pointer-events: none;
    }
  }

  .dropdown-item:active, .dropdown-item:focus {
    background-color: $cyan;
    outline: none;
  }

  button {
    align-items: center;
    background-color: transparent !important;
    color: $gray-600 !important;
    display: flex;
    font-weight: 300;
    justify-content: space-between;
    height: 100% !important;
    width: 100%;
  }
}

.dropdown-menu {
  font-size: 0.9rem !important;

  .dropdown-item {
    color: $gray-600 !important;
    justify-content: normal;
    padding-bottom: 6px;
    padding-top: 6px;

    i {
      width: 25px;
    }
  }

  .dropdown-item:hover {
    background-color: $gray-200 !important;
  }

  .dropdown-header {
    color: $gray-600 !important;
    font-size: 0.7rem !important;
  }
}

.dropdown-outline {
  .dropdown-toggle {
    border: 1px solid $gray-400 !important;
  }

  .dropdown-toggle:focus, .dropdown-toggle:hover  {
    border-color: $cyan !important;
    box-shadow: none !important;;
  }
}

.dropdown-no-outline {
  border-radius: 5px;

  button {
    background-color: transparent;
    border: none;
    color: $white !important;
    font-size: 1.2rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  button:focus {
    background-color: #FFFFFF4A !important;
    box-shadow: none !important;
  }

  button:hover {
    background-color: #FFFFFF4A !important;
  }
}


/* Forms.
 ------------------------------------------------- */
.form-group {
  margin-bottom: 1.3rem !important;
}

.input-group.is-invalid {
  ~ .invalid-feedback {
    display: block;
  }
}

.input-radio {
  font-weight: 300 !important;
  display: block;
  justify-content: space-between;
  width: 428px !important;

  .input-custom-option-row {
    display: flex;
    align-items: center;
    label {
      width: 25% !important;
    }
    .input-custom-option {
      width: 100%;
    }
  }
  label {
    font-size: 0.9rem;
    padding-right: 1rem !important;
    width: 150px !important;
  }
  input[type="radio"] {
    margin-right: 1rem !important;
  }
}

.invalid-feedback {
  font-size: 0.7rem !important;
  position: absolute;
}


/* Navbars.
 ------------------------------------------------- */
.navbar {
  padding: 1rem 2.5rem !important;
}


/* Progress.
 ------------------------------------------------- */
.progress {

  .progress-bar {
    background-color: $cyan;

    .progress-message {
      font-size: 0.7rem !important;
      padding: 5px !important;
      margin: auto !important;
      color: $black;
      font-weight: 600;
    }
  }
}
