@import './bootstrap-overrides.scss';
@import './page-template.scss';


/* Auto height
-------------------------------------------------- */
@mixin heightCalc($numerator, $denominator) {
  height: calc((100% / #{$denominator}) * #{$numerator});
  padding-bottom: 15px;
  padding-top: 15px;
}

.full-height {
  @include heightCalc(1, 1);
}

.half-height {
  @include heightCalc(1, 2);
}

.seven-eighth-height {
  @include heightCalc(7, 8);
}

.one-eighth-height {
  @include heightCalc(1, 8);
}

.nine-tenth-height {
  @include heightCalc(9, 10);
}

.one-tenth-height {
  @include heightCalc(1, 10);
}


/* Auto width
-------------------------------------------------- */
.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}


/* Generic
-------------------------------------------------- */
.ap-icon {
  display: flex;
  height: 20px;

  img {
    height: 100%;
  }
}

.bill-of-materials-type {
  align-items: center;
  border-radius: 100%;
  color: $white;
  display: flex;
  font-size: 0.7rem;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  width: 40px;

  &.pcba {
    background-color: $cyan;
  }

  &.wh {
    background-color: $teal;
  }
}

i[disabled] {
  border-color: $gray-200 !important;
  pointer-events: none;
}

.icon-button {
  border-radius: 100% !important;
  font-size: 1rem !important;
  height: 35px !important;
  padding: 5px !important;
  width: 35px !important;
}

.no-padding {
  padding: 0 !important;
}

.not-link {
  color: inherit !important;
}

.not-link:hover {
  text-decoration: none !important;
}

.selected {
  background-color: #17A2B810 !important;
  border: 1px solid $cyan !important;

  &.green {
    border: 1px solid $green;
    background-color: #28A74510;
  }

  &.green:hover {
    border: 1px solid $green;
  }
}

.panel-title {

  .icon-button {
    background-color: $white;
    border-width: 1px;
    font-size: 1rem !important;

    &[disabled] {
      background-color: $gray-100;
      border-color: $gray-500 !important;
      color: $gray-600 !important;
    }
  }
}

/* Account.
-------------------------------------------------- */
.account-card {
  width: 100%
}

.account-form {

  .credential-summary {
    align-items: center;
    color: $gray-600;
    display: flex;
    font-size: 0.8rem;

    &.green {
      color: $green;
    }

    &.red {
      color: $red;
    }

    &.yellow {
      color: $yellow;
    }
  }
}

.account-form-panel {

  .panel-title {
    height: 66px;
  }

  .panel-footer {
    display: flex;
    justify-content: space-between
  }
}


/* Bill of Materials.
-------------------------------------------------- */
.bill-of-materials-card-wrapper {
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .bill-of-materials-card {

    .card-body {
      display: flex;
      justify-content: space-between;

      .card-text-left {
        align-items: center;
        display: flex;

        .info {
          display: flex;
          flex-direction: column;
        }
      }

      .card-text-right {
        display: flex;

        .property {
          align-items: center;
          border-right: 1px solid $gray-400;
          display: flex;
          flex-direction: column;
          font-size: 0.6rem;
          justify-content: center;
          padding: 5px 10px;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

.bill-of-materials-remaining-work-wrapper {
  height: 100%;
  overflow-y: hidden;
}

.bill-of-materials-remaining-work {
  align-items: center;
  display: flex;
  max-height: inherit;
  width: 100%;

  &.is-minimized {
    bottom: 0;
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;

    .progress {
      height: 100%;
      width: 100%;
    }
  }
}

.bill-of-materials-upload {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;

  .dropzone {
    margin-bottom: 1rem;
  }
}


/* Confirmation Modal
-------------------------------------------------- */
.confirmation-modal {
  width: 35%;

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;

    h2 {
      margin-bottom: 15px;
    }

    p {
      color: $gray-500;
    }

    svg {
      font-size: 4rem;
      margin-bottom: 25px;

      &.danger {
        color: $danger;
      }

      &.primary {
        color: $primary;
      }
    }
  }

  .modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
  }
}


/* Dropzone.
-------------------------------------------------- */
.dropzone {
  align-items: center;
  background-color: #fafafa;
  border-color: #eeeeee;
  border-radius: 4px;
  border-style: dashed;
  border-width: 2px;
  color: #BDBDBD;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  outline: none;
  padding: 20px;
  transition: border .2s ease-in-out;
  width: 100%;

  .file-name {
    text-align: center;
    word-break: break-word;
  }
}

.dropzone:hover {
  border-color: $cyan;
  cursor: pointer;
}


/* Header.
-------------------------------------------------- */
.back-button {

  .btn {
    background-color: #0000002E;
    border: none;
    border-radius: 0;
    height: 100%;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
  }

  .btn:hover {
    background-color: #FFFFFF4A !important;
    box-shadow: none !important;
  }
}

.btn {

  &.transparent {
    background-color: transparent;
    border: none;
    color: $white !important;
    font-size: 1.2rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  &.transparent:focus, &.transparent:hover {
    background-color: #FFFFFF4A !important;
    box-shadow: none !important;
  }
}


/* Line Item.
-------------------------------------------------- */
.line-item-list {

  .title-left {
    align-items: center;
    display: flex;
  }
}

.line-item-card {
  display: flex; 
  flex-direction: row;
  width: 100%;

  &.card {
    display: flex; 
    flex-direction: row;
  }

  .number-parts {
    font-size: 0.8rem;
  }

  .number-of-parts {
    text-align: right;
  }

  .ask-for-selection {
    font-size: 0.8rem;
    font-style: italic;
    color: $red;
  }

  .card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .body-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .body-left {
        display: flex;
        flex-direction: column;
      }

      .body-right {
        display: flex;
        flex-direction: column;
        text-align: right;
      }
    }
  }
}


/* Paginator.
-------------------------------------------------- */
.paginator {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {

    .dropdown {
      min-width: 60px;
    }
  }

  .right {
    display: flex;
  }

  .arrow {
    border: solid $gray-600;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;

    &.left {
      transform: rotate(135deg);
    }

    &.right {
      transform: rotate(-45deg);
    }
  }

  .control {
    margin-left: 15px;
    text-align: center;
    width: 40px;

    i {
      border-color: $gray-400;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
    }

    i:hover {
      border-color: $gray-500;
    }
  }
}


/* Part.
-------------------------------------------------- */
.add-part-modal {
  min-width: 900px;
  width: 40%;

  .modal-header {
    align-items: center;
    display: flex;

    .modal-title {
      align-items: center;
      display: flex;
    }
  }
}

.part-card {
  width: 100%;

  .card-body {

    .action-required-label {
      align-items: center;
      color: $red;
      display: flex;
      font-weight: 600;
      margin-bottom: 0.25rem;

      svg {
        margin-right: 5px;
      }

      .message {
        font-size: 0.7rem;
      }
    }

    .card-subtitle {
      font-size: 0.9rem;
      margin-bottom: 1rem !important;
      margin-top: 2rem !important;
    }

    .card-title {
      margin-bottom: 1rem !important;
    }

    .divider {
      background-color: $gray-300;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .properties {
      display: flex;

      .datasheet-icon {
        color: $gray-700 !important;
        font-size: 1.5rem;
        justify-content: center;
      }

      .property {

        .value {

          &.missing {
            color: $red;
          }
        }
      }
    }

    .description {
      margin-bottom: 1rem;
    }

    .user-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 20px;

      svg {
        font-size: 18px;
      }
    }

    .part-card-status {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }

  .property {
    border-right: 1px solid $gray-300;
    border-radius: 3px;
    display: inline-flex;
    font-size: 0.7rem;
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 15px;
    padding-right: 15px;

    .title {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .property:last-child {
    margin-right: 0;
    border-right: 1px solid transparent;
  }
}

.part-list {

  .panel-title {
    height: 79px;
  }
}


/* Status icon.
-------------------------------------------------- */
.status-icon {
  margin-right: 5px;

  &.form-message-warning {
    margin-inline-start: 4px;
  }

  &.blue {
    color: $cyan;
  }

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }

  &.yellow {
    color: $yellow;
  }
}
