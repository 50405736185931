//
// page-template.scss
//
// Styles in this file should only affect the global layout
// and presentation of any given aPriori Web App.
//


@import '~apriori-react-core/src/styles/global';


body {
  height: 100vh;
  min-height: 800px;
  min-width: 1200px;

  #root {
    height: 100%;

    header {
      height: 70px;

      .navbar {
        padding: 1rem;

        a:link{
          text-decoration: none!important;
        }

        .brand {
          align-items: flex-start;
          display: flex;

          .app-name {
            color: $white;
            font-size: 1.2rem;
            font-weight: 300;
          }

          .vertical-bar {
            background-color: $white;
            height: 28px;
            margin-left: 13px;
            margin-right: 10px;
            width: 2px;
          }
        }

        .help-dropdown,
        .user-dropdown {

          .dropdown-item {

            svg {
              margin-right: 15px;
            }
          }
        }

        .nav-right {
          align-items: center;
          display: flex;
        }

        .user-dropdown {
          font-size: 0.9rem;

          .dropdown-menu {
            min-width: 350px;

            .dropdown-item {

              .active-account {
                align-items: center;
                display: flex;
                height: 25px;
                width: 100%;

                .progress {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    main {
      display: flex;
      flex-wrap: wrap;
      height: calc(100% - 130px);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 15px;
      width: 100%;

      .panel {

        .subtitle {
          color: $gray-600;
          font-size: 0.7rem;
        }

        .panel-filter {

          .filter-actions {
            align-items: center;
            border-bottom: 1px solid $gray-200;
            display: flex;
            padding: 10px;

            &.collapse:not(.show) {
              display: none;
            }

            .cost-status {
              margin: 0 10px;
              width: 50%;
            }

            .search {
              color: $gray-600;
              font-size: 0.9rem !important;
              height: 38px;
              margin: 0 10px;
              width: 50%;
            }

            .search:focus, .search:hover {
              border-color: $cyan !important;
              box-shadow: none !important;;
            }
          }
        }
      }
    }
  }
}
